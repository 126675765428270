<template>
  <v-card 
    :loading="loading"
    loader-height="2"
    :ripple="false"
    class="m-cycles transparent rounded-lg"
    @click="toggle"
  >
    <div 
      class="cycles-container pt-4"
    >
      <div 
        :class="{ 'pulse animate': loading }"
        class="cycles d-flex scrollable x snap"
      >
        <div 
          v-for="cycle in list"
          :key="cycle.id"
          :data-id="cycle.id"
          class="cycle snap-target"
          v-intersect="{ handler: onIntersect, options: { threshold: [.1, .25] } }"
        >
          <div class="header d-flex mb-2 px-5">
            <span class="title text-overline">
              Ciclo {{ cycle.title }}
            </span>
            <v-spacer />
            <span 
              :class="[(cycle.warning ? 'warning' : 'primary')+'--text']"
              class="total"
            >
              <number
                :to="cycle.total"
                :from="cycle.total>10 ? cycle.total-10 : 0"
                :format="formatTotal"
                :duration="2"
                :class="[(cycle.warning ? 'warning' : 'primary')+'--text']"
                class="total mr-n1"
              />H
            </span>
          </div>
          <portal-target 
            :name="cycle.id+'-labels'" 
            class="m-chart--grid-container px-5"
          />
          <m-chart
            :ref="'chart-'+cycle.id"
            type="column"
            :data="cycle.chart"
            :color="settings.colors"
            :format="settings.format"
            :min-height="height.min"
            :max-height="height.max"
            :size="settings.size"
            :step="settings.step"
            :position="settings.position"
            :scroller="cycle.id+'-labels'"
            full
            class="chart pl-12 pr-4"
          >
            <template v-slot:empty>
              <v-icon 
                size="8"
                color="error"
                class="empty-bar mb-1"
              >
                {{ icons.empty }}
              </v-icon>
            </template>
          </m-chart>
        </div>
      </div>

      <v-divider class="my-0" />

      <v-expand-transition>
        <div 
          v-if="controller.toggle"
          class="legends mx-3 mt-5 overflow-hidden d-flex align-stretch rounded"
        >
          <div
            v-for="(legend, key) in controller.legends"
            :key="key"
            :class="[legend.color]"
            class="legend text-no-wrap text-caption flex-grow-1 px-2 py-1 d-flex"
          >
            <span class="text-truncate d-inline-block">
              {{ legend.title }}: 
            </span>
            <span class="d-inline-block flex-shrink-0">
              {{ selected[key] }}
            </span>
          </div>
        </div>
      </v-expand-transition>

      <div class="footer pa-2 d-flex align-center">
        <span
          :class="[(briefing.warning ? 'warning' : 'grey')+'--text']"
          class="briefing text-caption pa-3"
          :inner-html.prop="briefing[controller.toggle ? 'text' : 'summary'] | formatter(formatText)"
        />
        <v-spacer />
        <v-btn
          icon
          color="grey"
          class="btn-help mr-1"
          @click.stop="toggleHelp"
        >
          <v-icon
            small
            class="icon"
            v-text="icons.help"
          />
        </v-btn>
      </div>
    </div>
    <!-- 
    <h6 class="text-body-2 text--secondary my-4">
      <span style="color: #698df2">
        Compensadas: <b>{{ chart.data.compensated }}</b>
      </span> 
      <v-divider vertical class="my-0 mx-2 mb-n1" />
      <span style="color: #45D9CF">
        Válidas: <b>{{ chart.data.valid }}</b>
      </span>
      <v-divider vertical class="my-0 mx-2 mb-n1" />
      <span style="color: #FF7C5C">
        Inválidas: <b>{{ chart.data.invalid }}</b>
      </span>
    </h6>
 -->
    <div class="card-background grey darken-3 rounded-lg" />
  </v-card>
</template>

<style scoped lang="scss">

  .m-cycles {
    position: relative;
    .cycles-container {
      position: relative;
      z-index: 1;
    }
    .cycles {
      scroll-margin: 16px;
    }
    .cycle {
      // &:not(:last-child) {
      //   padding-right: 16px;
      // }
      .header, .m-chart--grid-container {
        position: sticky;
        top: 0;
        left: 0;
        right: 0;
        width: calc(100vw - 32px);
        
        .total {
          font-size: 2rem !important;
          font-weight: 900;
          letter-spacing: 0.075rem;
        }
      }
      .empty-bar {
        opacity: .8;
      }
      .m-chart--grid-container {
        left: 20px;
        width: calc(100vw - 32px - 20px);
      }
    }
    .briefing {
      line-height: 1.5 !important;
    }
    &::before {
      opacity: 0 !important;
    }
  }
</style>

<script>

  import services from '@/services'
  import { 
    mdiHelpCircleOutline,
    mdiCircleOutline
  } from '@mdi/js';

  export default {

    props: {
      data: {
        type: Object,
        default: () => {}
      },
      goal: {
        type: Number,
        default: 160
      },
      loading: {
        type: Boolean,
        default: false
      },
      updated: {
        type: Boolean,
        default: false
      },
      user: {
        type: Object,
        default: () => {}
      },
    },

    data: () => ({
      icons: {
        help: mdiHelpCircleOutline,
        empty: mdiCircleOutline
      },
      controller: {
        toggle: false,
        selected: null,
        scrolling: false,
        data: {},
        legends: {
          'valid': {
            title: 'Válidas',
            color: 'primary background--text'
          }, 
          'compensated': {
            title: 'Compensadas',
            color: 'secondary',
          },
          'invalid': {
            title: 'Inválidas',
            color: 'error',
          }, 
        }
      },
      settings: {
        minHeight: '16vh',
        fullHeight: '20vh',
        step: null,
        size: 12,
        format: '0[.]0',
        colors: [
          'secondary',
          'primary',
          'error',
        ],
        position: 'end'
      }
    }),

    computed: {
      cycles () {
        let data = this.controller.data;
        const now = this.$moment();
        const partnerAt = this.partnerAt;
        console.log('cycles')

        if (_.isEmpty(data)) {
          const newbie = this.newbie;
          data = {
            [this.current]: {
              start: newbie ? partnerAt : now.startOf('month').format('YYYY-MM-DD'),
              end: now.endOf('month').format('YYYY-MM-DD'),
              valid: 0,
              invalid: 0,
              compensated: 0,
              daily: []
            }
          };
          data = this.process(data);
        }else if (!(this.current in data)) {
          console.log('missing')
          const current = this.process({ [this.current]: {
            start: now.startOf('month').format('YYYY-MM-DD'),
            end: now.endOf('month').format('YYYY-MM-DD'),
            valid: 0,
            invalid: 0,
            compensated: 0,
            daily: []
          }});
          data = { ...data, ...current }
        }
        return data;
      },

      list () {
        const cycles = this.cycles;
        const list = _.orderBy(_.values(cycles), ['id'], ['asc']);
        return list;
      },

      current () {
        return this.getCycleId();
      },

      selected () {
        const cycles = this.cycles;
        const selected = this.controller.selected;
        const cycle = _.has(cycles, selected) ? cycles[selected] : null;
        return cycle;
      },

      briefing () {
        const selected = this.controller.selected;
        const loading = this.loading;
        const cycles = this.cycles;
        let text, summary, warning;
        if (!!selected&&!loading&&selected in cycles) {
          const current = this.current;
          const cycle = cycles[selected];
          const payday = this.$moment(this.getPayday(cycle, 'YYYY-MM-DD'));

          warning = cycle.warning;
          
          if (selected==current) {
            if (cycle.projected>0) {
              text = warning ? 
                `O acumulado de horas está abaixo do esperado (${cycle.expected}h). Para alcançar o mínimo de ${cycle.goal}h você deve fazer ${cycle.projected}h/dia de trabalho.` : 
                `Mantenha um ritmo de ${cycle.projected}h/dia de trabalho para alcançar o mínimo 160 horas no Ciclo. 👍`;
              summary = `Total/Esperado até agora: *${cycle.total}h/${cycle.expected}h* \n Mínimo diário: *${cycle.projected}h*`;
            }else{
              text = summary = 'Você garantiu sua renda extra! 🎉 Mantenha o Buzzer ligado ao rodar.'
            }
          }else{
            summary = text = warning ? 
              `*Você não alcançou o mínimo de 160h no Ciclo ${cycle.title}.*` : 
              this.$moment().isBefore(payday) ? 
                `Ciclo completo! *O pagamento será feito no dia ${payday.format('DD/MM')} até 23h59.*` : `Ciclo fechado. Pagamento realizado no dia ${payday.format('DD/MM')}.`;
          }
        }else{
          summary = text = 'Carregando...';
        }
        return { 
          text, 
          summary, 
          warning 
        };
      },

      height () {
        const full = this.controller.toggle;
        const settings = this.settings;
        const height = {
          min: full ? settings.fullHeight : settings.minHeight,
          max: null
        };
        return height;
      },

      partnerAt () {
        const partnerAt = this.$moment.utc(this.user.statusChange).local().format('YYYY-MM-DD');
        return partnerAt;
      },

      newbie () {
        const firstCycle = this.getCycleId(this.partnerAt);
        const newbie = !!firstCycle&&this.current==firstCycle;
        return newbie;
      },

      // hasNextCycle () {
      //   const key = this.getCycleId(this.$moment(this.workhours.selected).add(1, 'M'));
      //   return _.has(this.workhours.cycles, key);
      // },
      // hasPrevCycle () {
      //   const key = this.getCycleId(this.$moment(this.workhours.selected).subtract(1, 'M'));
      //   return _.has(this.workhours.cycles, key);
      // },
    },
    watch: {
      data: {
        immediate: true,
        deep: true,
        handler (data) {
          setTimeout(($) => {
            $.controller.data = $.process(_.cloneDeep(data));
          }, 1000, this)
        }
      },
      updated: {
        immediate: true,
        handler (updated, before) {
          if (!before&&updated) {
            // scroll to target
            setTimeout(($) => {
              $.scroll();
            }, 2000, this);
          }
        }
      }
    },

    filters: {
      formatter (text, formatter=v=>v) {
        return formatter(text);
      },
    },

    methods: {
      ...services,

      toggle () {
        this.controller.toggle = true;
      },

      process (data={}) {
        const goal = this.goal;
        const now = this.$moment();
        const partnerAt = this.partnerAt;
        const cycles = _.mapValues(data, (cycle, id) => {
          const start = this.$moment(partnerAt).isAfter(cycle.start) ? partnerAt : cycle.start;
          const end = cycle.end;
          const projected = this.getDailyProjection(cycle);
          let chart = _.map([...Array(this.$moment(cycle.end).diff(cycle.start, 'd')+1).keys()], i => {
            const day = this.$moment(cycle.start).add(i, 'd').format('YYYY-MM-DD');
            const history = _.find(cycle.daily, ['day', day]);
            const d = _.isNil(history) ? {
              day: day,
              valid: 0,
              invalid: 0,
              compensated: 0,
            } : history;
            d.projected = projected!==null&&this.$moment().isSameOrBefore(day, 'day') ? projected - d.valid - d.compensated : null;
            const isToday = now.isSame(day, 'day');
            return [{ format: 'D', value: day, pending: isToday, highlight: isToday }, d.compensated, d.valid, d.invalid, d.projected];
          });

          const title = this.$moment(cycle.start).format('DD') + '-' + this.$moment(cycle.end).format('DD') + ' ' + this.$moment(cycle.start).format('MMM').toUpperCase();
          const total = _.round(cycle.valid + cycle.compensated, 1);
          const days = this.$moment(cycle.end).date();
          const cycleGoal = (goal/days) * this.$moment(cycle.end).diff(cycle.start, 'days', true);
          const base = cycleGoal / days;
          const expected = _.floor((this.$moment().diff(cycle.start, 'd', true)) * base);
          const warning = !!projected ? total < expected : cycleGoal > total;
          const current = projected!==null;

          return {
            id,
            title,
            chart,
            start,
            end,
            goal: _.ceil(cycleGoal),
            total,
            expected,
            projected,
            valid: cycle.valid,
            invalid: cycle.invalid,
            compensated: cycle.compensated,
            current,
            warning
          };
        });
        return cycles;
      },

      scroll (smooth=true, target=null) {
        let ready = true;
        const cycles = this.cycles;
        const current = this.current;
        const last = this.$moment().subtract(1, 'M').format('YYYY-MM');
        if (!target) target = this.$moment().date() < 9 && _.has(cycles, last) ? last : current;
        const cycle = _.has(cycles, target) ? cycles[target] : null;

        if (!!cycle) {
          const bar = 'bar-' +  this.$moment(target==last ? cycle.end : undefined).format('DD');
          const ref = 'chart-'+cycle.id;
          if (_.has(this.$refs, ref)) {
            const chart = this.$refs[ref][0];
            this.controller.scrolling = true;
            // chart.$el.scrollIntoView();
            this.$nextTick(() => {
              if (_.has(chart.$refs, bar)) {
                chart.$refs[bar][0].scrollIntoView({ behavior: smooth ? 'smooth' : 'instant', inline: 'center', block: 'nearest' });
                setTimeout(($) => {
                  $.controller.scrolling = false;
                }, 500, this);
              }
            })
          }else{
            ready = false;
          }
          this.controller.selected = target;
        }else{
          ready = false;
        }
        if (!ready) {
          setTimeout(($) => {
            $.scroll(smooth);
          }, 200, this);
        }
      },

      toggleHelp () {
        this.$emit('help', { section: 'goal' });
      },

      // switchCycle (next=true) {
      //   const valid = _.isBoolean(next) ? next ? this.hasNextCycle : this.hasPrevCycle : _.has(this.workhours.cycles, next);
      //   const key = _.isBoolean(next) ? this.getCycleId(this.$moment(this.workhours.selected)[next ? 'add': 'subtract'](1, 'M')) : next;
      //   if (valid) {
      //     this.$set(this.workhours, 'selected', key);
      //     this.$nextTick(() => {
      //       this.setCycleChart();
      //     })
      //   }
      //   console.log(valid, key, this.workhours.selected);
      // },

      // setCycleChart () {
      //   const cycles = this.workhours.cycles;
      //   const selected = this.workhours.selected;
      //   if (_.isNil(cycles)||_.isEmpty(cycles)||_.isNil(selected)||!_.has(cycles, selected)) return null;
        
      // },

      getDailyProjection (cycle) {
        const today = this.$moment().startOf('D');
        const days = today.isAfter(cycle.end) ? 0 : this.$moment(cycle.end).diff(today, 'd')+1;
        const total = cycle.valid + cycle.compensated;
        const goal = this.goal;
        const left = goal - total;

        const projected =  days <= 0 ? null : _.round(left/days, 1);

        return projected;
      },

      getPayday (cycle, format='DD/MM') {
        const end = this.$moment(cycle.end);
        const payday = end.add(10, 'days').format(format);
        return payday;
      },

      getCycleId (date=Date.now()) {
        const m = this.$moment(date);
        return m.format('YYYY-MM');
      },

      formatTotal (n) {
        return _.round(n, 1);
      },

      onIntersect (entries, observer) {
        const instance = entries[0];
        const id = instance.target.dataset.id;
        if (instance.intersectionRatio>=0.1) {
          if (!this.controller.scrolling&&this.controller.selected!=id) this.controller.selected = id;
        }
      },
    },

     mounted () {
      this.process();
      this.$nextTick(() => {
        this.scroll(false, this.current);
      });
     },

    components: {
      MChart: () => import('@/components/mChart'),
      Loading: () => import('@/components/IconLoading'),
    },
  }
</script>